html{
    background-color: #1c1c1c;
}

/* Font */
@font-face {
    font-family: 'TikTok Font Bold';
    src: url('./fonts/TikTok Sans Bold.woff2') format('woff2'),
    url('./fonts/TikTok Sans Bold.woff') format('woff'),
    url('./fonts/TikTok Sans Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'TikTok Font Regular';
    src: url('./fonts/TikTok Sans Regular.woff2') format('woff2'),
    url('./fonts/TikTok Sans Regular.woff') format('woff'),
    url('./fonts/TikTok Sans Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Running Writing';
    src: url('./fonts/MeeMee Kids Lettering.woff2') format('woff2'),
    url('./fonts/MeeMee Kids Lettering.woff') format('woff'),
    url('./fonts/MeeMee Kids Lettering.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.typing-effect .cursor {
    display: inline-block;
    background-color: red; /* Initial color */
    width: 2px !important;
    height: 1em !important;
    animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
    0%, 100% {
        background-color: rgb(255, 255, 255); /* Color visible most of the time */
    }
    50% {
        background-color: transparent; /* Color during the 'blink' */
        width: 5px; /* Increase width during blink */
    }
}



#root {
    width: 100%; /* full width of the viewport */
    min-height: 100vh; /* at least full height of the viewport */
    display: flex; /* enable flexbox */
    flex-direction: column; /* stack children vertically */
    align-items: center; /* center children horizontally */
}
#AppContainer{
    width: 80%; /* The container takes the full width of its parent */
}


/* Left Panel */
#leftPanel{
    position: absolute;
    left: 0;
    width: 10%;
    height: 100%;
    top: 0;
    z-index: 2;
    background-color: #181818;

    display: flex; /* enable flexbox */
    flex-direction: column; /* stack children vertically */
    align-items: center; /* center children horizontally */
}
#leftPanelLite, .leftPanelMoving{
    display: none;
}
#leftPanelLetterD{
    background-color: black;
    height: 25%;
    width: 100%;
    position: relative;
}
#letterD{
    font-size:8rem;
    font-family: "TikTok Font Bold", serif;
    color: #fd3851; /* Primary color for the text */
    text-shadow: -7px 0 0 #00dfd8; /* Blue-ish shadow effect to the left */
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}
#role{
    font-family: "TikTok Font Regular", serif;
    top: 90%;
    transform: translateY(-90%);
    position: absolute;
    text-align: center;
    font-size:1rem;
    color: grey;
    width: 100%;
}
#leftPanelButtonCSS{
    width: 100%;
}
#leftPanelProjectButton, #leftPanelAboutButton, #leftPanelContactButton, #leftPanelCapabilitiesButton, #leftPanelBlogHomeButton{
    background-color: transparent;
    color: white;
    width: 100%;
    font-size: 1rem;
    font-family: 'TikTok Font Regular', serif;
    border: 0;
}
#leftPanelProjectButton:hover, #leftPanelAboutButton:hover, #leftPanelContactButton:hover, #leftPanelCapabilitiesButton:hover, #leftPanelBlogHomeButton:hover{
    cursor: pointer;
}
#leftPanelAboutDiv, #leftPanelProjectDiv, #leftPanelCapabilitiesDiv, #leftPanelContactDiv, #leftPanelBlogHomeDiv{
    padding: 12.5%;
}
#leftPanelProjectDiv, #leftPanelContactDiv, #leftPanelCapabilitiesDiv{
    border: 0 solid grey;
    border-top-width: 1px;
}
#leftPanelContactDiv{
    border-bottom-width: 1px;
}
#leftPanelBlogHomeDiv{
    border: 0 solid grey;
    border-bottom-width: 1px;

}
#socialsLeftPanel{
    position: relative;
    padding-top: 10%;
    width: 100%;
    height: 4%;
}
#githubImageLeftPanel, #linkedinImageLeftPanel{
    position: absolute;
}
#githubImageLeftPanel{
    left: 30%;
    transform: translateX(-30%);
}
#linkedinImageLeftPanel{
    left: 70%;
    transform: translateX(-70%);
}


/* Heroshot */
#heroshotContainer {
    width: 100%; /* full width of the root container */
    box-sizing: border-box; /* include padding and border in the element's total width and height */
    color: white;
    min-height: 100vh;
    height: fit-content;
    position: relative;
    padding-top: 15%;
    padding-left: 5%;
}
#heroshotHeading1, #heroshotHeading2, #typingEffect {
    height: fit-content;
    font-family: "TikTok Font Bold", serif;
    font-size: 5.5rem;
    padding-top: 2.5%;
    width: fit-content;
}
#cursor {
    position: relative;
    display: inline-block;
    font-family: "TikTok Font Regular", serif;
    font-size: 5.5rem;
    font-weight: 100;
    color: transparent; /* Hide the text color */
}

#cursor::after {
    content: '|';
    position: absolute;
    left: 0;
    color: rgb(255, 255, 255); /* Visible color of the cursor */
    animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
}





#contrastLetter{
    font-size: 6rem;
}
#heroshotHeading2{
    word-spacing: 15px;
}
#currentStatus{
    font-family: "TikTok Font Regular", serif;
    width: fit-content;
    font-size: 1.3rem;
    margin-top: 11%;
    color: grey;
}
#scrollDown{
    font-family: 'Tiktok Font Regular', serif;
    transform: rotate(90deg);
    width: fit-content;
    margin-left: -7%;
    top: 80%;
    position: absolute;
}


.bounce {
    animation: bounce 0.5s ease forwards;
}
@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}


/* About Section */
#aboutContainer{
    color: white;
    position: relative;
    width: 100%;
    min-height: 100vh;
    height: fit-content;
}


#aboutMeHeading, #techStackHeading, #capabilitiesHeading{
    font-family: 'TikTok Font Bold', serif;
    font-size: 3.5rem;
}


#aboutMeBlock{
    width: 40%;
    text-align: center;
    position: relative;
    left: 10%;
    padding-top: 7.5%;
}
#aboutMeText{
    font-family: 'TikTok Font Regular', serif;
    font-size: 1.05rem;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    padding-top: 7.5%;
}


#techStackText{
    font-family: 'TikTok Font Regular', serif;
    font-size: 1rem;
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
}
#techStackBlock{
    position: absolute;
    left: 50%;
    top: 0;
    text-align: center;
    width: 40%;
    padding-top: 7.5%;
}
#HTML, #CSS, #JavaScript, #React, #Python, #Github, #SQL, #Java, #Figma, #WebScraping, #IFTTTWebhook, #AWS, #reCaptchaBypass, #botBypass, #Flask, #Selenium{
    background-color: #d5d5d5;
    color: #333;
    border: 1px solid #000000;
    border-radius: 20px; /* Adjust for rounded corners */
    padding: 15px 25px;
    display: inline-block;
    margin: 5px;
    font-size: 0.9em;
    text-align: center;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);

}


/* Transitions */
.aboutMe-enter {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-10%);
    transition: all 0.5s;
    will-change: opacity, filter, transform; /* Hint to browsers to optimize */
}
.aboutMe-enter.visible {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}


.aboutMe-enter2 {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(10%);
    transition: all 0.5s;
    will-change: opacity, filter, transform; /* Hint to browsers to optimize */
}
.aboutMe-enter2.visible {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}


.aboutMe-enter3 {
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-10%);
    transition: all 1s;
    will-change: opacity, filter, transform; /* Hint to browsers to optimize */
}
.aboutMe-enter3.visible {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}

.arrow-container {
    position: relative;
    height: 200px; /* Adjust as needed */
    width: 2px; /* Line thickness */
    background: grey; /* Line color */
    margin-left: 50px; /* Adjust as needed */
    border-top-right-radius: 50px; /* Adjust as needed */
}

.arrow-container::before {
    content: '';
    position: absolute;
    left: -10px; /* Half the width to center the line */
    bottom: 0;
    height: 50px; /* Adjust as needed */
    width: 20px; /* Arrow width */
    border-bottom: 2px solid grey; /* Adjust color and thickness as needed */
    border-right: 2px solid grey; /* Adjust color and thickness as needed */
    border-bottom-right-radius: 20px; /* Adjust as needed */
}

.arrow-container::after {
    content: '';
    position: absolute;
    left: -10px; /* Adjust as needed to center the arrow */
    bottom: -10px; /* Adjust as needed */
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid grey; /* Arrow color */
}






/* Project Card Container */
#projectCardContainer{
    position: relative;
    height: 130vh;
    width: 100%;
    margin-top: 10%;
}
#projectsContainerHeading{
    font-family: 'Tiktok Font Bold', serif;
    font-size: 3.5rem;
    color: white;
    position: relative;
    margin-top: 5%;
    text-align: center;
}
#cardSection{
    width: 75%;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    height: 100vh;
    margin-top: 3%;
}
.projectCard{
    width: 40%;
    height: 40vh;
    color: white;
    position: relative;
    padding-top: 0 !important;
}
#cardOne, #cardThree{
    left: 10%;
}
#cardTwo{
    left:50%;
    top: 0;
    position: absolute;
}
#cardFour{
    left:50%;
    top: 40vh;
    position: absolute;
}
#cardFive{
    display: none;
    left: 50%;
    transform: translateX(-50%);
}
#cardThree, #cardFour, #cardFive{
    padding-top: 4%;
}
.projectCardVideo{
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    height: 60% !important;
    position: relative;
    border: none;
}
.projectCardTitle{
    font-family: 'TikTok Font Bold', serif;
    text-align: center;
    font-size: 1.05em;
}
.projectCardDescription{
    font-family: 'TikTok Font Regular', serif;
    text-align: center;
    font-size: 0.9em;
}
.projectCardGitHub{
    color: blue;
    text-align: center;
    position: absolute;
    padding-top: 5%;
    width: 50% !important;
    font-family: 'Tiktok Font Regular', serif;
}
#localHost{
    cursor: not-allowed;
    color: blue;
    text-align: center;
    position: absolute;
    padding-top: 5%;
    left: 50%;
    width: 50% !important;
    font-family: 'Tiktok Font Regular', serif;
}
#cardFive .projectCardGitHub{
    display: none;
}
#cardFive #localHost{
    width: 100% !important;
    left: 0;
}
.projectCardWebsite{
    color: blue;
    text-align: center;
    position: absolute;
    padding-top: 5%;
    left: 50%;
    width: 50% !important;
    font-family: 'Tiktok Font Regular', serif;
}
#loadMoreProjectsButton{
    font-family: 'Tiktok Font Regular', serif;
    width: 12.5%;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    background-color: transparent;
    color: white;
    outline: white 1px solid;
    display: block;
    padding: 8px;
    border: none;
    margin-top: 5%;
}
#loadMoreProjectsButton:hover{
    cursor: pointer;
}


/* Contact Section */
#contactSection{
    height: 100vh;
}
#contactHeading{
    font-family: 'TikTok Font Bold', serif;
    font-size: 3.5rem;
    text-align: center;
    color: white;
    padding-top: 5%;
}
.contactMethods{
    text-align: center;
    margin-top: 5%;
}
#contactSectionGitHub, #contactSectionLinkedin, #contactSectionGmail{
    color: white;
    font-family: 'Tiktok Font Regular', serif;
    font-size: 1.5rem;
    text-decoration: underline;
}
.contactSectionGitHub, .contactSectionGmail, .contactSectionLinkedin{
    width: fit-content;
    padding-left: 1.5%;
    color: white;
}
#contactSectionGitHub, #contactSectionGmail, #contactSectionLinkedin{
    display: flex;        /* Establishes a flex container */
    flex-direction: row;  /* Lays out children in a row */
    align-items: center;  /* Centers items vertically */
    justify-content: center; /* Centers items horizontally */
}


/* Capabilities Section */
#capabilitiesSection{
    min-height: 100vh;
    height: fit-content;
}
#capabilitiesHeading{
    font-family: 'Tiktok Font Bold', serif;
    font-size: 3.5rem;
    color: white;
    position: relative;
    text-align: center;
}
#combinationText{
    position: relative;
    font-family: 'Tiktok Font Regular', serif;
    font-size: 1.25rem;
    color: #8a939d;
    width: 75%;
    text-align: center;
    margin: 1% auto auto;
}
#capabilityOne{
    width: 70%;
    position: relative;
    height: 25%;
    margin: 5% auto 0;
}
#capabilityVideoOne{
    position: absolute;
    left: 50%;
    top: 0;
}

#capabilityTwo{
    width: 70%;
    position: relative;
    height: 25%;
    margin: 5% auto 0;
}
#capabilityVideoTwo{
    position: absolute;
    left: 50%;
    top: 0;
}

#video{
    border-radius: 20px;
}
.title{
    font-family: 'TikTok Font Bold', serif;
    font-size: 1.75rem;
    color: white;
    width: 35%;
    margin-left: 7.5%;
    padding-top: 2.5%;
    position: relative;
}
.description{
    font-family: 'TikTok Font Regular', serif;
    font-size: 1.05rem;
    color: #8a939d;
    margin-top: 2%;
    width: 40%;
    margin-left: 7.5%;
}
.cta{
    font-family: 'TikTok Font Bold', serif;
    font-size: 1rem;
    color: white;
    width: fit-content;
    margin-left: 7%;
    text-decoration: none;
    position: relative;
}
.capabilityButton{
    background-color: transparent;
    border: none;
    color: white;
    font-family: 'TikTok Font Bold', serif;
    margin-top: 4%;
    font-size: 1rem;
    left: 0;
    margin-left: 0;
}
.capabilityButton:hover{
    cursor: pointer;
}

.cta::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -3px; /* Adjust this value to position the underline */
    width: 0;
    height: 2px;
    background-color: white; /* Adjust color as needed */
    visibility: hidden;
    opacity: 0;
    transition: width 0.2s ease, visibility 0s linear 0.2s, opacity 0.2s ease;
}
.cta:hover{
    cursor: pointer;
}
.cta:hover::after {
    width: 100%; /* Causes the underline to span the full width of the content */
    visibility: visible; /* Makes the pseudo-element visible when hovered */
    opacity: 1;
    transition: width 0.2s ease, visibility 0s, opacity 0.2s ease;
}

/* WebScraping.js */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
#webScrapingContainer, #captchaBypassContainer{
    width: 80%;
    position: relative;
    height: 90vh;
    top: 0;
}
.blogPostTitleClass{
    font-family: 'Tiktok Font Bold', serif;
    font-size: 3.5rem;
    color: white;
    position: relative;
    text-align: center;
    margin-top: 4%;
}

#blogPostVideoDiv{
    height: 70%;
    border-radius: 20px;
    left: 52.5%;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;

}

.blogPostTextAreaDiv{
    margin-top: 4%;
    width: 42.5%;
    margin-left: 7.5%;
    white-space: pre-line;
    color: white;
    font-size: 1rem;
    font-family: 'Tiktok Font Regular', serif;
    padding: 2%;
}

#textAndVideoContainer{
    position: relative;
}






/* Footer Section */
#footerContainer{
    width: 100%;
}
#footer{
    background-color: #181818;
    width: 100%;
    height: 24%;
    position: absolute;
    left: 0;
}
#aboutFooter, #projectsFooter, #contactFooter, #capabilitiesFooter{
    text-align: center;
    margin-top: 1%;
}
#aboutFooterButton, #projectsFooterButton, #contactFooterButton, #capabilitiesFooterButton{
    background-color: transparent;
    border: none;
    color: white;
    font-family: 'Tiktok Font Regular', serif;
    font-size: 0.9em;
}
#aboutFooterButton:hover, #projectsFooterButton:hover, #contactFooterButton:hover, #capabilitiesFooterButton:hover{
    cursor: pointer;
}

#createdBy{
    color: grey;
    text-align: center;
    font-family: 'Tiktok Font Regular', serif;
    font-size: 0.8em;
    margin-top: 1%;
}


@media only screen and (min-width: 1441px) and (max-width: 1700px){

    .title{
        font-size: 1.45rem;
    }
    .description{
        font-size: 0.95rem;
    }
    .cta{
        font-size: 1rem;
    }

}

@media only screen and (min-width: 1441px) and (max-width: 1919px){

    #contactSection{
        margin-top: 15%;
    }

    #webScrapingContainer, #captchaBypassContainer {
        min-height: 100vh;
        width: 90%;
        left: 5%;
    }

    .blogPostTitleClass{
        width: 100%;
    }
    #blogPostVideoDiv{
        height: 62.5%;
        left: 50%;
    }
    .blogPostTextAreaDiv{
        margin-left: 2%;
        margin-top: 5%;
        width: 47.5%;
        height: fit-content;
    }


    #cardSection{
        width: 95%;
        margin-top: 2%;
    }
    .projectCard{
        width: 45%;
        padding-top: 0 !important;
    }
    .projectCardVideo{
        width: 95%;
        height: 80% !important;
    }
    #cardOne, #cardThree{
        margin-left: 0;
    }
    #cardTwo, #cardFour{
        margin-left: 5%;
    }
    #cardOne, #cardThree{
        left: 0;
    }
    #cardThree{
        top: 12.5%;
    }
    #cardFour{
        top: 52.5%;
    }
    #cardFive{
        margin-top: 17.5%;
    }
    #loadMoreProjectsButton{
        outline: white 1px solid;
        margin-top: 17.5%;
    }
}

@media only screen and (min-width: 1281px) and (max-width: 1440px){

    #contactSection{
        margin-top: 15%;
    }

    #webScrapingContainer, #captchaBypassContainer {
        min-height: 100vh;
        width: 90%;
        left: 5%;
    }

    .blogPostTitleClass{
        width: 100%;
        padding-left: 5%;
        padding-right: 5%;
    }
    #blogPostVideoDiv{
        height: 55%;
        left: 50%;

    }
    .blogPostTextAreaDiv{
        margin-left: 2%;
        margin-top: 2%;
        width: 47.5%;
        height: fit-content;
        font-size: 0.95rem;
    }


    .title{
        font-size: 1.6rem;
    }
    .description{
        font-size: 1rem;
    }
    .cta{
    }
    .capabilityButton{
        font-size: 1.1rem;
        margin-top: 2%;
    }
    #capabilityTwo{
        margin-top: 9.5%;
    }
    #capabilityVideoOne, #capabilityVideoTwo{
        width: 45%;
    }
    #capabilityOne, #capabilityTwo{
        width: 100%;
        height: 35%;
    }


    #HTML, #CSS, #JavaScript, #React, #Python, #Github, #SQL, #Java, #Figma, #WebScraping, #IFTTTWebhook, #AWS, #reCaptchaBypass, #botBypass, #Flask, #Selenium{
        background-color: #d5d5d5;
        color: #333;
        border: 1px solid #000000;
        border-radius: 20px; /* Adjust for rounded corners */
        padding: 15px 25px;
        display: inline-block;
        margin: 5px;
        font-size: 0.75em;
        text-align: center;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    }
    #aboutMeHeading, #techStackHeading, #projectsContainerHeading, #contactHeading, #capabilitiesHeading{
        font-family: 'TikTok Font Bold', serif;
        font-size: 3rem;
    }
    #combinationText{
        font-size: 1.2rem;
    }

    #cardSection{
        width: 95%;
        margin-top: 2%;
    }
    .projectCard{
        width: 50%;
        padding-top: 0 !important;
    }
    .projectCardVideo{
        width: 90%;
        height: 80% !important;
    }
    #cardOne, #cardThree{
        margin-left: -5%;
    }
    #cardTwo, #cardFour{
        margin-left: 5%;
    }
    #cardOne, #cardThree{
        left: 0;
    }
    #cardThree{
        top: 12.5%;
    }
    #cardFour{
        top: 52.5%;
    }
    #cardFive{
        margin-top: 24%;
    }
    #loadMoreProjectsButton{
        outline: white 1px solid;
        margin-top: 20%;
    }

}


/* ONLY FOR CAPABILITIES - Had to split @media query into two for Capabilities to adjust to viewport size */
@media only screen and (min-width: 1101px) and (max-width: 1280px){

    #webScrapingContainer, #captchaBypassContainer {
        min-height: 100vh;
        width: 90%;
        left: 5%;
    }

    .blogPostTitleClass{
        width: 100%;
        left: 0;
        font-size: 3rem;
        padding-left: 5%;
        padding-right: 5%;
    }
    #blogPostVideoDiv{
        height: 47.5%;
        left: 50%;

    }
    .blogPostTextAreaDiv{
        margin-left: 2%;
        margin-top: 2%;
        width: 47.5%;
        height: fit-content;
        font-size: 0.9em;
    }

    .title{
        font-size: 1.5rem;
    }
    .description{
        font-size: 0.95rem;
    }
    .cta{
    }
    .capabilityButton{
        font-size: 1rem;
        margin-top: 2%;
    }
    #capabilityTwo{
        margin-top: 11%;
    }
    #capabilityVideoOne, #capabilityVideoTwo{
        width: 45%;
    }
    #capabilityOne, #capabilityTwo{
        width: 100%;
        height: 35%;
    }
}
/* ONLY FOR CAPABILITIES - Had to split @media query into two for Capabilities to adjust to viewport size */
@media only screen and (min-width: 1025px) and (max-width: 1100px){


    #webScrapingContainer, #captchaBypassContainer {
        min-height: 100vh;
        width: 90%;
        left: 5%;
    }

    .blogPostTitleClass{
        width: 100%;
        left: 0;
        font-size: 3rem;
        padding-left: 5%;
        padding-right: 5%;
    }
    #blogPostVideoDiv{
        height: 42.5%;
        left: 50%;

    }
    .blogPostTextAreaDiv{
        margin-left: 2%;
        margin-top: 2%;
        width: 47.5%;
        height: fit-content;
        font-size: 0.9em;
    }


    .title{
        font-size: 1.3rem;
    }
    .description{
        font-size: 0.85rem;
    }
    .cta{
    }
    .capabilityButton{
        font-size: 0.9rem;
        margin-top: 2%;
    }
    #capabilityTwo{
        margin-top: 17.5%;
    }
    #capabilityVideoOne, #capabilityVideoTwo{
        width: 45%;
    }
    #capabilityOne, #capabilityTwo{
        width: 100%;
        height: 35%;
    }
}


@media only screen and (min-width: 1025px) and (max-width: 1280px) {

    #contactSection{
        margin-top: 15%;
    }

    #role{
        font-size:0.8rem;
    }
    #HTML, #CSS, #JavaScript, #React, #Python, #Github, #SQL, #Java, #Figma, #WebScraping, #IFTTTWebhook, #AWS, #reCaptchaBypass, #botBypass, #Flask, #Selenium{
        padding: 10px 21px;
        font-size: 0.75em;
    }
    #aboutMeHeading, #techStackHeading, #projectsContainerHeading, #contactHeading, #capabilitiesHeading{
        font-family: 'TikTok Font Bold', serif;
        font-size: 3rem;
    }
    #combinationText{
        font-size: 1.1rem;
    }

    #githubImageLeftPanel{
        left: 20%;
        transform: translateX(-20%);
    }
    #linkedinImageLeftPanel{
        left: 80%;
        transform: translateX(-80%);
    }

    #heroshotHeading1, #heroshotHeading2, #typingEffect {
        font-size: 4.85rem;
    }
    #cursor {
        font-size: 5rem;
    }
    #contrastLetter{
        font-size: 5.35rem;
    }
    #cardThree, #cardFour, #cardFive{
        padding-top: 4%;
    }

    #loadMoreProjectsButton{
        width: 15%;
    }
    #cardSection{
        width: 95%;
        margin-top: 2%;
    }
    .projectCard{
        width: 50%;
        padding-top: 0 !important;
    }
    .projectCardVideo{
        width: 95%;
        height: 80% !important;
    }
    #cardOne, #cardThree{
        margin-left: -5%;
    }
    #cardTwo, #cardFour{
        margin-left: 5%;
    }
    #cardOne, #cardThree{
        left: 0;
    }
    #cardThree{
        top: 12.5%;
    }
    #cardFour{
        top: 52.5%;
    }
    #cardFive{
        margin-top: 24%;
    }
    #loadMoreProjectsButton{
        outline: white 1px solid;
        margin-top: 20%;
    }

}


/* ONLY FOR CAPABILITIES - Had to split @media query into two for Capabilities to adjust to viewport size */
@media only screen and (min-width: 900px) and (max-width: 1024px){

    #webScrapingContainer, #captchaBypassContainer {
        min-height: 100vh;
        width: 90%;
        left: 5%;
    }

    .blogPostTitleClass{
        width: 100%;
        left: 0;
        font-size: 2.5rem;
        padding-left: 5%;
        padding-right: 5%;

    }
    #blogPostVideoDiv{
        height: 42.5%;
        left: 50%;

    }
    .blogPostTextAreaDiv{
        margin-left: 2%;
        margin-top: 2%;
        width: 47.5%;
        height: fit-content;
        font-size: 0.9em;
    }

    .title{
        font-size: 1.25rem;
    }
    .description{
        font-size: 0.8rem;
    }
    .cta{
    }
    .capabilityButton{
        font-size: 0.9rem;
        margin-top: 2%;
    }
    #capabilityTwo{
        margin-top: 17.5%;
    }
    #capabilityVideoOne, #capabilityVideoTwo{
        width: 45%;
    }
    #capabilityOne, #capabilityTwo{
        width: 100%;
        height: 35%;
    }
}
/* ONLY FOR CAPABILITIES - Had to split @media query into two for Capabilities to adjust to viewport size */
@media only screen and (min-width: 822px) and (max-width: 899px){

    #webScrapingContainer, #captchaBypassContainer {
        min-height: 100vh;
        width: 90%;
        left: 5%;
    }

    .blogPostTitleClass{
        width: 100%;
        left: 0;
        font-size: 2.5rem;
        padding-left: 5%;
        padding-right: 5%;

    }
    #blogPostVideoDiv{
        height: 42.5%;
        left: 50%;

    }
    .blogPostTextAreaDiv{
        margin-left: 2%;
        margin-top: 2%;
        width: 47.5%;
        height: fit-content;
        font-size: 0.8em;
    }

    .title{
        font-size: 1.15rem;
    }
    .description{
        font-size: 0.75rem;
    }
    .cta{
    }
    .capabilityButton{
        font-size: 0.9rem;
        margin-top: 2%;
    }
    #capabilityTwo{
        margin-top: 17.5%;
    }
    #capabilityVideoOne, #capabilityVideoTwo{
        width: 45%;
    }
    #capabilityOne, #capabilityTwo{
        width: 100%;
        height: 35%;
    }
}


@media only screen and (min-width: 822px) and (max-width: 1024px){

    #leftPanel, #leftPanelLetterD, #letterD, #role{
        display: none;
    }

    #leftPanelLite{
        position: absolute;
        left: 0;
        width: 10%;
        height: 100%;
        top: 0;
        z-index: 4;
        background-color: #181818;
        display: flex; /* enable flexbox */
        flex-direction: column; /* stack children vertically */
        align-items: center; /* center children horizontally */
    }
    .leftPanelMoving{
        width: 80vw;
        height: 100%;
        background-color: #181818;
        z-index: 3;
        position: absolute;
        transform: translateX(-100%); /* Hide it off-screen to the left */
        top: 0;
        opacity: 0; /* Start with it being invisible */
        transition: opacity 0.2s, transform 0.5s; /* Animate both opacity and transform */
    }
    .leftPanelMoving.visible {
        transform: translateX(-5%); /* Move into view */
        opacity: 1; /* Make it fully visible */
    }


    #leftPanelLiteMenuImage{
        background-color: transparent;
        border: none;
        left: 20px;
        top: 10%;
        position: absolute;
    }

    #liteAboutButton, #liteProjectsButton, #liteContactButton, #liteCapabilitiesButton, #liteHomeButton{
        display: block;
        background-color: transparent;
        color: white;
        width: 100%;
        font-size: 1rem;
        font-family: 'TikTok Font Regular', serif;
        border: 0;
    }
    #liteHomeDiv{
        margin-top: 18%;
        border: 0 solid grey;
        border-top-width: 1px;
        border-bottom-width: 1px;
        padding: 4%;
    }
    #liteAboutDiv, #liteProjectsDiv, #liteContactDiv, #liteCapabilitiesDiv{
        padding: 5%;
    }
    #liteAboutDiv{
        margin-top: 18%;
        border: 0 solid grey;
        border-top-width: 1px;
        border-bottom-width: 1px;
    }
    #liteContactDiv{
        border: 0 solid grey;
        border-top-width: 1px;
        border-bottom-width: 1px;
    }
    #liteProjectsDiv{
        border: 0 solid grey;
        border-top-width: 1px;
    }

    .leftPanelMoving{
        display: block;
    }

    #aboutMeHeading, #techStackHeading, #projectsContainerHeading, #contactHeading, #capabilitiesHeading{
        font-size: 2.7rem;
    }
    #combinationText{
        font-size: 1.05rem;
    }
    #aboutMeBlock, #techStackBlock{
        width: 45%;
    }
    #aboutMeBlock{
        left: 5%
    }
    #techStackText{
        width: 80%;
    }
    #HTML, #CSS, #JavaScript, #React, #Python, #Github, #SQL, #Java, #Figma, #WebScraping, #IFTTTWebhook, #AWS, #reCaptchaBypass, #botBypass, #Flask, #Selenium{
        padding: 10px 22px;
    }

    #cardSection{
        width: 95%;
        margin-top: 2%;
    }
    .projectCard{
        width: 50%;
    }
    .projectCardVideo{
        width: 110%;
    }
    #cardOne, #cardThree{
        margin-left: -5%;
    }
    #cardTwo, #cardFour{
        margin-left: 5%;
    }
    #cardOne, #cardThree{
        left: 0;
    }
    #cardThree, #cardFour{
        padding-top: 5%;
    }
    #cardFive{
        padding-top: 4%;
    }
    #loadMoreProjectsButton{
        width: 15%;
    }




}

@media only screen and (min-width: 769px) and (max-width: 821px){


    #webScrapingContainer, #captchaBypassContainer{
        min-height: 100vh;
        width: 90%;
        left: 5%;
    }

    .blogPostTitleClass{
        width: 100%;
        left: 0;
        font-size: 2.25rem;
        padding-left: 5%;
        padding-right: 5%;
    }
    #blogPostVideoDiv{
        display: none;
    }
    .blogPostTextAreaDiv{
        margin-left: 2%;
        margin-top: 5%;
        width: 95%;
        height: fit-content;
        font-size: 0.9em;
        position: relative;
    }


    .title{
        font-size: 0.95rem;
    }
    .description{
        font-size: 0.75rem;
    }
    .capabilityButton{
        font-size: 0.8rem;
        margin-top: 2%;
    }
    #capabilityTwo{
        margin-top: 17.5%;
    }
    #capabilityVideoOne, #capabilityVideoTwo{
        width: 45%;
    }
    #capabilityOne, #capabilityTwo{
        width: 100%;
        height: 35%;
    }


    #currentStatus{
        margin-top: 18%;
    }
    #scrollDown{
        margin-left: -11%;
    }

    #leftPanel, #leftPanelLetterD, #letterD, #role{
        display: none;
    }

    #leftPanelLite{
        position: absolute;
        left: 0;
        width: 10%;
        height: 100%;
        top: 0;
        z-index: 4;
        background-color: #181818;
        display: flex; /* enable flexbox */
        flex-direction: column; /* stack children vertically */
        align-items: center; /* center children horizontally */
    }
    .leftPanelMoving{
        width: 80vw;
        height: 100%;
        background-color: #181818;
        z-index: 3;
        position: absolute;
        transform: translateX(-100%); /* Hide it off-screen to the left */
        top: 0;
        opacity: 0; /* Start with it being invisible */
        transition: opacity 0.2s, transform 0.5s; /* Animate both opacity and transform */
    }
    .leftPanelMoving.visible {
        transform: translateX(-5%); /* Move into view */
        opacity: 1; /* Make it fully visible */
    }


    #leftPanelLiteMenuImage{
        background-color: transparent;
        border: none;
        left: 12px;
        top: 10%;
        position: absolute;
    }

    #liteAboutButton, #liteProjectsButton, #liteContactButton, #liteCapabilitiesButton, #liteHomeButton{
        display: block;
        background-color: transparent;
        color: white;
        width: 100%;
        font-size: 1rem;
        font-family: 'TikTok Font Regular', serif;
        border: 0;
    }
    #liteHomeDiv{
        margin-top: 18%;
        border: 0 solid grey;
        border-top-width: 1px;
        border-bottom-width: 1px;
        padding: 4%;
    }
    #liteAboutDiv, #liteProjectsDiv, #liteContactDiv, #liteCapabilitiesDiv{
        padding: 5%;
    }
    #liteAboutDiv{
        margin-top: 18%;
        border: 0 solid grey;
        border-top-width: 1px;
        border-bottom-width: 1px;
    }
    #liteProjectsDiv{
        border: 0 solid grey;
        border-top-width: 1px;
    }
    #liteContactDiv{
        border: 0 solid grey;
        border-top-width: 1px;
        border-bottom-width: 1px;
    }

    .leftPanelMoving{
        display: block;
    }

    #aboutMeHeading, #techStackHeading, #projectsContainerHeading, #contactHeading, #capabilitiesHeading{
        font-size: 2.5rem;
    }
    #combinationText{
        font-size: 1.05rem;
    }
    #aboutMeBlock, #techStackBlock{
        width: 48%;
    }
    #aboutMeBlock{
        left: 2%
    }
    #techStackText{
        width: 80%;
    }
    #HTML, #CSS, #JavaScript, #React, #Python, #Github, #SQL, #Java, #Figma, #WebScraping, #IFTTTWebhook, #AWS, #reCaptchaBypass, #botBypass, #Flask, #Selenium{
        padding: 10px 22px;
    }

    #cardSection{
        width: 95%;
        margin-top: 2%;
    }
    .projectCard{
        width: 50%;
    }
    .projectCardVideo{
        width: 125%;
    }
    #cardOne, #cardThree{
        margin-left: -7.5%;
    }
    #cardTwo, #cardFour{
        margin-left: 7.5%;
    }
    #cardOne, #cardThree{
        left: 0;
    }
    #cardThree, #cardFour{
        padding-top: 5%;
    }
    #cardFive{
        padding-top: 4%;
    }
    #loadMoreProjectsButton {
        width: 15%;
    }





}


/* ONLY FOR CAPABILITIES - Had to split @media query into two for Capabilities to adjust to viewport size */
@media only screen and (min-width: 621px) and (max-width: 768px){
    .title{
        font-size: 0.8rem;
        margin-left: 5%;
        width: 35%;
    }
    .description{
        font-size: 0.725rem;
        margin-left: 5%;
        width: 39%;
    }
    .cta{
        margin-left: 5%;
    }
    .capabilityButton{
        font-size: 0.8rem;
        margin-top: 2%;
    }
    #capabilityTwo{
        margin-top: 22.5%;
    }
    #capabilityVideoOne, #capabilityVideoTwo{
        width: 55%;
        left: 45%;
        top: 50%;
        transform: translateY(-50%);
    }
    #capabilityOne, #capabilityTwo{
        width: 100%;
        height: 35%;
    }
}
/* ONLY FOR CAPABILITIES - Had to split @media query into two for Capabilities to adjust to viewport size */
@media only screen and (min-width: 501px) and (max-width: 620px){
    .title{
        font-size: 0.8rem;
        margin-left: 4%;
        width: 39%;
    }
    .description{
        font-size: 0.725rem;
        margin-left: 4%;
        width: 39%;
    }
    .cta{
        margin-left: 4%;
    }
    .capabilityButton{
        font-size: 0.8rem;
        margin-top: 2%;
    }
    #capabilityTwo{
        margin-top: 22.5%;
    }
    #capabilityVideoOne, #capabilityVideoTwo{
        width: 55%;
        left: 45%;
        top: 50%;
        transform: translateY(-50%);
    }
    #capabilityOne, #capabilityTwo{
        width: 100%;
        height: 35%;
    }
}


@media only screen and (min-width: 501px) and (max-width: 768px){

    #webScrapingContainer, #captchaBypassContainer{
        min-height: 100vh;
        width: 90%;
        left: 5%;
    }

    .blogPostTitleClass{
        width: 100%;
        left: 0;
        font-size: 2.25rem;
        padding-left: 5%;
        padding-right: 5%;
    }
    #blogPostVideoDiv{
        display: none;
    }
    .blogPostTextAreaDiv{
        margin-left: 2%;
        margin-top: 5%;
        width: 95%;
        height: fit-content;
        font-size: 0.9em;
        position: relative;
    }


    #currentStatus{
        margin-top: 18%;
    }
    #scrollDown{
        margin-left: -11%;
    }

    #heroshotHeading1, #heroshotHeading2, #typingEffect {
        font-size: 4.5rem;
    }
    #cursor {
        font-size: 4.75rem;
    }
    #contrastLetter{
        font-size: 5rem;
    }
    #heroshotHeading1 {
        margin-top: 22%;
    }

    #leftPanel, #leftPanelLetterD, #letterD, #role{
        display: none;
    }

    #leftPanelLite{
        position: absolute;
        left: 0;
        width: 10%;
        height: 100%;
        top: 0;
        z-index: 4;
        background-color: #181818;
        display: flex; /* enable flexbox */
        flex-direction: column; /* stack children vertically */
        align-items: center; /* center children horizontally */
    }
    .leftPanelMoving{
        width: 80vw;
        height: 100%;
        background-color: #181818;
        z-index: 3;
        position: absolute;
        transform: translateX(-100%); /* Hide it off-screen to the left */
        top: 0;
        opacity: 0; /* Start with it being invisible */
        transition: opacity 0.2s, transform 0.5s; /* Animate both opacity and transform */
    }
    .leftPanelMoving.visible {
        transform: translateX(-5%); /* Move into view */
        opacity: 1; /* Make it fully visible */
    }


    #leftPanelLiteMenuImage{
        background-color: transparent;
        border: none;
        left: 10px;
        top: 10%;
        position: absolute;
    }
    #liteButtonImg{
        height: 30px;
    }

    #liteAboutButton, #liteProjectsButton, #liteContactButton, #liteCapabilitiesButton, #liteHomeButton{
        display: block;
        background-color: transparent;
        color: white;
        width: 100%;
        font-size: 1rem;
        font-family: 'TikTok Font Regular', serif;
        border: 0;
    }
    #liteHomeDiv{
        margin-top: 18%;
        border: 0 solid grey;
        border-top-width: 1px;
        border-bottom-width: 1px;
        padding: 4%;
    }
    #liteAboutDiv, #liteProjectsDiv, #liteContactDiv, #liteCapabilitiesDiv{
        padding: 5%;
    }
    #liteAboutDiv{
        margin-top: 18%;
        border: 0 solid grey;
        border-top-width: 1px;
        border-bottom-width: 1px;
    }
    #liteProjectsDiv{
        border: 0 solid grey;
        border-top-width: 1px;
    }
    #liteContactDiv{
        border: 0 solid grey;
        border-top-width: 1px;
        border-bottom-width: 1px;
    }

    .leftPanelMoving{
        display: block;
    }

    #aboutMeHeading, #techStackHeading, #projectsContainerHeading, #contactHeading, #capabilitiesHeading{
        font-size: 1.75rem;
    }
    #combinationText{
        font-size: 0.9rem;
    }
    #aboutMeBlock, #techStackBlock{
        width: 50%;
    }
    #aboutMeBlock{
        left: 0;
    }
    #aboutMeText, #techStackText{
        width: 100%;
        font-size: 0.95rem;
    }
    #HTML, #CSS, #JavaScript, #React, #Python, #Github, #SQL, #Java, #Figma, #WebScraping, #IFTTTWebhook, #AWS, #reCaptchaBypass, #botBypass, #Flask, #Selenium{
        padding: 10px 22px;
    }

    #cardSection{
        width: 95%;
        margin-top: 2%;
    }
    .projectCard{
        width: 50%;
    }
    .projectCardVideo{
        width: 110%;
    }
    #cardOne, #cardThree{
        margin-left: -5%;
    }
    #cardTwo, #cardFour{
        margin-left: 5%;
    }

    #cardOne, #cardThree{
        left: 0;
    }
    #cardThree, #cardFour, #cardFive{
        padding-top: 10%;
    }
    #loadMoreProjectsButton {
        width: 25%;
        margin-top: 10%;
    }

    .projectCardTitle{
        font-size: 0.9em;
    }
    .projectCardDescription, .projectCardGitHub, #localHost, .projectCardWebsite {
        font-size: 0.8em;
    }


    #contactSectionGitHub, #contactSectionLinkedin, #contactSectionGmail{
        font-size: 1.2rem;
    }
    .contactImages{
        height: 48px
    }






}

@media only screen and (min-width: 335px) and (max-width: 500px){

    #webScrapingContainer, #captchaBypassContainer{
        min-height: 100vh;
        width: 90%;
        left: 5%;
    }

    .blogPostTitleClass{
        width: 100%;
        left: 0;
        font-size: 2rem;
        padding-left: 5%;
        padding-right: 5%;
    }
    #blogPostVideoDiv{
        display: none;
    }
    .blogPostTextAreaDiv{
        margin-left: 2%;
        margin-top: 5%;
        width: 95%;
        height: fit-content;
        font-size: 0.9em;
        position: relative;
    }



    .title{
        font-size: 0.9rem;
        margin-left: 0;
        width: 100%;
        text-align: center;
        position: relative;
    }
    .description{
        font-size: 0.7rem;
        margin-left: 5%;
        width: 90%;
        text-align: center;
        position: relative;

    }
    .cta {
        margin: auto;
        position: absolute; /* Change to absolute */
        bottom: -8%; /* Add this line to align to the bottom */
        left: 0; /* Align to the left */
        right: 0; /* Align to the right */
    }

    .capabilityButton{
        font-size: 0.9rem;
        margin-top: 2%;
    }
    #capabilityTwo{
        margin-top: 30%;
        margin-bottom: 15%;
    }
    #capabilityVideoOne, #capabilityVideoTwo{
        width: 85%;
        left: 50%;
        transform: translateX(-50%);
        position: relative;
        margin-top: 2%;
    }
    #capabilityOne, #capabilityTwo{
        width: 100%;
        height: 35%;
        position: relative;
    }

    #currentStatus{
        margin-top: 30%;
        font-size: 1rem;
    }
    #scrollDown{
        margin-left: -17%;
        font-size: 0.9rem;
    }

    #heroshotHeading1, #heroshotHeading2, #typingEffect {
        font-size: 3.25rem;
    }
    #cursor {
        font-size: 3.6rem;
    }
    #contrastLetter{
        font-size: 3.6rem;
    }
    #heroshotHeading1 {
        margin-top: 30%;
    }

    #leftPanel, #leftPanelLetterD, #letterD, #role{
        display: none;
    }

    #leftPanelLite{
        position: absolute;
        left: 0;
        width: 10%;
        height: 100%;
        top: 0;
        z-index: 4;
        background-color: #181818;
        display: flex; /* enable flexbox */
        flex-direction: column; /* stack children vertically */
        align-items: center; /* center children horizontally */
    }
    .leftPanelMoving{
        width: 80vw;
        height: 100%;
        background-color: #181818;
        z-index: 3;
        position: absolute;
        transform: translateX(-100%); /* Hide it off-screen to the left */
        top: 0;
        opacity: 0; /* Start with it being invisible */
        transition: opacity 0.2s, transform 0.5s; /* Animate both opacity and transform */
    }
    .leftPanelMoving.visible {
        transform: translateX(-5%); /* Move into view */
        opacity: 1; /* Make it fully visible */
    }


    #leftPanelLiteMenuImage{
        background-color: transparent;
        border: none;
        left: 5px;
        top: 10%;
        position: absolute;
    }
    #liteButtonImg{
        height: 23px;
    }

    #liteAboutButton, #liteProjectsButton, #liteContactButton, #liteCapabilitiesButton, #liteHomeButton{
        display: block;
        background-color: transparent;
        color: white;
        width: 100%;
        font-size: 1rem;
        font-family: 'TikTok Font Regular', serif;
        border: 0;
    }
    #liteHomeDiv{
        margin-top: 50%;
        border: 0 solid grey;
        border-top-width: 1px;
        border-bottom-width: 1px;
        padding: 4%;
    }
    #liteAboutDiv, #liteProjectsDiv, #liteContactDiv, #liteCapabilitiesDiv{
        padding: 5%;
    }
    #liteAboutDiv{
        margin-top: 50%;
        border: 0 solid grey;
        border-top-width: 1px;
        border-bottom-width: 1px;
    }
    #liteProjectsDiv{
        border: 0 solid grey;
        border-top-width: 1px;
    }
    #liteContactDiv{
        border: 0 solid grey;
        border-top-width: 1px;
        border-bottom-width: 1px;
    }

    .leftPanelMoving{
        display: block;
    }

    #aboutMeHeading, #techStackHeading{
        font-size: 1.55rem;
        margin-top: 20%;
    }

    #projectsContainerHeading, #contactHeading, #capabilitiesHeading{
        font-size: 1.65rem;
        padding-top: 20%;
    }
    #combinationText{
        font-size: 0.9rem;
    }
    #aboutMeBlock, #techStackBlock{
        width: 50%;
    }
    #aboutMeBlock{
        left: 0;
    }
    #aboutMeText, #techStackText{
        width: 85%;
        font-size: 0.7rem;
    }
    #HTML, #CSS, #JavaScript, #React, #Python, #Github, #SQL, #Java, #Figma, #WebScraping, #IFTTTWebhook, #AWS, #reCaptchaBypass, #botBypass, #Flask, #Selenium{
        padding: 6px 10px;
    }

    #cardSection{
        width: 95%;
        margin-top: 12%;
    }
    .projectCard{
        width: 50%;
    }
    .projectCardVideo{
        width: 110%;
    }
    #cardOne, #cardThree{
        margin-left: -5%;
    }
    #cardTwo, #cardFour{
        margin-left: 5%;
    }
    #cardOne, #cardThree{
        left: 0;
    }
    #cardThree, #cardFour, #cardFive{
        margin-top: 18%;
    }
    #loadMoreProjectsButton {
        width: 35%;
        margin-top: 20%;
    }

    .projectCardTitle{
        font-size: 0.825em;
    }
    .projectCardDescription, .projectCardGitHub, #localHost, .projectCardWebsite {
        font-size: 0.75em;
    }

    #contactSectionGitHub, #contactSectionLinkedin, #contactSectionGmail{
        font-size: 1rem;
        margin-top: 15%;
    }
    .contactImages{
        height: 38px
    }
    #contactHeading{
        padding-top: 50%;
    }


    #footer{
        background-color: #181818;
        width: 100%;
        height: 25%;
        position: absolute;
        left: 0;
    }

    #aboutFooter, #projectsFooter, #contactFooter, #capabilitiesFooter{
        text-align: center;
        margin-top: 4%;
    }






}

@media only screen and (max-width: 334px){

    #webScrapingContainer, #captchaBypassContainer{
        min-height: 100vh;
        width: 90%;
        left: 5%;
    }

    .blogPostTitleClass{
        width: 100%;
        left: 0;
        font-size: 2rem;
        padding-left: 5%;
        padding-right: 5%;
    }
    #blogPostVideoDiv{
        display: none;
    }
    .blogPostTextAreaDiv{
        margin-left: 2%;
        margin-top: 5%;
        width: 95%;
        height: fit-content;
        font-size: 0.9em;
        position: relative;
    }


    .title{
        font-size: 0.9rem;
        margin-left: 0;
        width: 100%;
        text-align: center;
        position: relative;
    }
    .description{
        font-size: 0.7rem;
        margin-left: 5%;
        width: 90%;
        text-align: center;
        position: relative;

    }
    .cta {
        margin: auto;
        position: absolute; /* Change to absolute */
        bottom: -8%; /* Add this line to align to the bottom */
        left: 0; /* Align to the left */
        right: 0; /* Align to the right */
    }

    .capabilityButton{
        font-size: 0.9rem;
        margin-top: 2%;
    }
    #capabilityTwo{
        margin-top: 30%;
        margin-bottom: 15%;
    }
    #capabilityVideoOne, #capabilityVideoTwo{
        width: 85%;
        left: 50%;
        transform: translateX(-50%);
        position: relative;
        margin-top: 2%;
    }
    #capabilityOne, #capabilityTwo{
        width: 100%;
        height: 35%;
        position: relative;
    }


    #currentStatus{
        margin-top: 30%;
        font-size: 1rem;
    }
    #scrollDown{
        margin-left: -17%;
        font-size: 0.9rem;
    }

    #heroshotHeading1, #heroshotHeading2, #typingEffect {
        font-size: 3.25rem;
    }
    #cursor {
        font-size: 3.5rem;
    }
    #contrastLetter{
        font-size: 3.6rem;
    }
    #heroshotHeading1 {
        margin-top: 30%;
    }

    #leftPanel, #leftPanelLetterD, #letterD, #role{
        display: none;
    }

    #leftPanelLite{
        position: absolute;
        left: 0;
        width: 10%;
        height: 100%;
        top: 0;
        z-index: 4;
        background-color: #181818;
        display: flex; /* enable flexbox */
        flex-direction: column; /* stack children vertically */
        align-items: center; /* center children horizontally */
    }
    .leftPanelMoving{
        width: 80vw;
        height: 100%;
        top: 0;
        background-color: #181818;
        z-index: 3;
        position: absolute;
        transform: translateX(-100%); /* Hide it off-screen to the left */
        opacity: 0; /* Start with it being invisible */
        transition: opacity 0.2s, transform 0.5s; /* Animate both opacity and transform */
    }
    .leftPanelMoving.visible {
        transform: translateX(-5%); /* Move into view */
        opacity: 1; /* Make it fully visible */
    }


    #leftPanelLiteMenuImage{
        background-color: transparent;
        border: none;
        left: 2px;
        top: 10%;
        position: absolute;
    }
    #liteButtonImg{
        height: 18px;
    }

    #liteAboutButton, #liteProjectsButton, #liteContactButton, #liteCapabilitiesButton, #liteHomeButton{
        display: block;
        background-color: transparent;
        color: white;
        width: 100%;
        font-size: 1rem;
        font-family: 'TikTok Font Regular', serif;
        border: 0;
    }
    #liteHomeDiv{
        margin-top: 50%;
        border: 0 solid grey;
        border-top-width: 1px;
        border-bottom-width: 1px;
        padding: 4%;
    }
    #liteAboutDiv, #liteProjectsDiv, #liteContactDiv, #liteCapabilitiesDiv{
        padding: 5%;
    }
    #liteAboutDiv{
        margin-top: 50%;
        border: 0 solid grey;
        border-top-width: 1px;
        border-bottom-width: 1px;
    }
    #liteProjectsDiv{
        border: 0 solid grey;
        border-top-width: 1px;
    }
    #liteContactDiv{
        border: 0 solid grey;
        border-top-width: 1px;
        border-bottom-width: 1px;
    }

    .leftPanelMoving{
        display: block;
    }

    #aboutMeHeading, #techStackHeading{
        font-size: 1.35rem;
        margin-top: 20%;
    }

    #projectsContainerHeading, #contactHeading, #capabilitiesHeading{
        font-size: 1.65rem;
        padding-top: 20%;
    }
    #combinationText{
        font-size: 0.9rem;
    }
    #aboutMeBlock, #techStackBlock{
        width: 50%;
    }
    #aboutMeBlock{
        left: 0;
    }
    #aboutMeText, #techStackText{
        width: 100%;
        font-size: 0.7rem;
    }
    #HTML, #CSS, #JavaScript, #React, #Python, #Github, #SQL, #Java, #Figma, #WebScraping, #IFTTTWebhook, #AWS, #reCaptchaBypass, #botBypass, #Flask, #Selenium{
        padding: 5px 12px;
    }

    #cardSection{
        width: 95%;
        margin-top: 12%;
    }
    .projectCard{
        width: 50%;
    }
    #cardOne, #cardThree{
        left: 0;
    }
    #cardThree, #cardFour, #cardFive{
        padding-top: 10%;
    }
    #loadMoreProjectsButton {
        width: 20%;
        margin-top: 6%;
    }

    .projectCardTitle{
        font-size: 0.6em;
    }
    .projectCardDescription, .projectCardGitHub, #localHost, .projectCardWebsite {
        font-size: 0.6em;
    }
    .projectCardVideo{
        height: 50%;
    }

    #contactSectionGitHub, #contactSectionLinkedin, #contactSectionGmail{
        font-size: 1rem;
        margin-top: 15%;
    }
    .contactImages{
        height: 38px
    }
    #contactHeading{
        padding-top: 50%;
    }

    #aboutFooter, #projectsFooter, #contactFooter, #capabilitiesFooter{
        text-align: center;
        margin-top: 4%;
    }
    #aboutFooterButton, #projectsFooterButton, #contactFooterButton, #capabilitiesFooterButton{
        font-size: 0.7em;
    }

    #createdBy{
        font-size: 0.6em;
    }





    }
